.debug {
  height: 100%;
  overflow-y: auto;
}

.debug p {
  user-select: auto !important;
}


.debug section {
  border-top: 1px solid black;
  padding: 20px 0px;
}

.debug section h2 {
  margin: 0;
  margin: 10px;
}

.debug .abTest table {
  margin: 0 auto;
}

.debug .abTest table tbody tr td:first-child {
  font-size: 10px;
}

.debug .abTest button {
  padding: 5px 10px;
}

.debug tr.highlightRow {
  background-color: #EEEEEE;
}

.debug thead tr.header {
  font-size: 20px;
}