a.playMoreLink, a.playMoreLink:hover, a.playMoreLink:active {
  display: block;
  margin: 10px;
  cursor: pointer;
  text-decoration: underline;
  font-size: 13em;
}

.actionLinks {
  display: flex;
  justify-content: center;
}

.sharemodal .modal__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content:center;
  max-width: 350px;
  background-color: #EDF1F2;
  border-radius: 15px;
  border: none;
  height: auto;
  min-height: 0;
  padding: 30px 15px;
  gap: 5px;
}

.sharemodal .ctahook {
  font-family: 'American Typewriter', serif;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  margin-top: 10px;
}

.sharemodal pre {
  width: 300px;
  font-weight: 400;
  white-space: pre-wrap;
  font-size: 14px;
  line-height: 19px;
  padding: 10px;
  font-family: 'Helvetica Neue';
  position: relative;
  background-color: #fff;
}

.sharemodal pre::after {
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  content: '';
  width: 0; 
  height: 0; 
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: 10px solid #EDF1F2;
}

.sharemodal button.twitter {
  width: 100%;
  box-shadow: 0px 3px 0px #2C4C7B;
}

.actionLinks .buttons-stacked {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  width: 100%;
  max-width: 300px;
  padding: 15px;
}

.actionLinks .buttons-stacked button {
  width: 100%;
}

.actionLinks .buttons-stacked .playMoreLink {
  background-color: #FF9A24;
  color: #fff;
  box-shadow: 0px 3px 0px #B77B6E;
}

.actionLinks > button.playMoreLink {
  margin-top: 15px;
  background-color: #FF9A24;
  box-shadow: 0px 3.36264px 0px #B77B6E;
  padding: 10px 50px;
}

.actionLinks > button.playMoreButton {
  margin-top: 15px;
  background-color: #FF9A24;
  box-shadow: 0px 3.36264px 0px #B77B6E;
  padding: 16px 50px;
  min-width: 270px;
  font-size: 1.6em;
}

.link-style {
  border: none;
  padding: 0;
  margin: 5px 0 0 0;
  background-color: transparent;
  text-decoration: underline;
  font-weight: 400;
  color: #969696;
  font-size: 16px;
  font-family: 'American Typewriter', serif;
}

.resultsContainer.weeklyab {
  height: 360px;
  margin-top: -155px;
  background-color: #D2D3D8;
  box-sizing: border-box;
  z-index: 1;
}

.resultsContainer.weeklyab .resultContent {
  height: 100%;
}

.resultsContainer.weeklyab .resultContent .primary {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
}

playpass-share {
  z-index: 2;
}

.winoverlay {
  position: absolute;
  top: -55px;
  left: 0;
  width: 100%;
  height: calc(100% + 55px);
  background-color: rgba(0,0,0,0);
  transition: all 1s linear;
  transition-delay: 1s;
  pointer-events: none;
}

.winoverlay.on {
  background-color: rgba(0,0,0,.4);
}