.announcementToast .widthConstrainer {
  display: flex;
  flex-direction: column;
  max-width: 800px;
  align-items: flex-start;
  padding-top: 10px;
  padding-bottom: 10px;
  text-align: left;
  width: 100%;
}

.announcementToast {
  background-color: #EDF1F2;
}

.announcementToast .widthConstrainer h1.title {
  margin: 0;
  padding-left: 20px;
  font-family: var(--main-font);
  font-size: 22px;
  color: #111418
}

.announcementToast .widthConstrainer h5.subtitle {
  margin: 0;
  padding-left: 20px;
  font-weight: normal;
  font-size: 14px;
  color: #111418;
}

.announcementToast .content {
  margin-top: 10px;
  width: 100%;
}
.announcementToast .row {
  display: flex;
  align-items: flex-start;
  max-width: 800px;
  margin: 0 auto;
}

@media only screen and (max-width: 600px) {
.announcementToast .row img.toastImage {
  width: 40px;
  height: 40px;
  margin-left: 10px;
  margin-top: 10px;
}
}

@media only screen and (min-width: 600px) {
.announcementToast .row img.toastImage {
  width: 75px;
  height: 75px;
  margin-left: 10px;
  margin-top: 10px;
}
}

.announcementToast .closeButton {
  width: 20px;
  height: 20px;
  margin-right: 10px;
  margin-top: 10px;
  cursor: pointer;
  float: right;
  background-image: url('../skin/assets/btnX.svg');
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}
