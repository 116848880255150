#settingsScreen {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%
}

#settingsScreen h2, #settingsScreen .moreinfo {
  flex-grow: 0;
}


#settingsScreen .settings {
  flex-grow: 1;
}


#settingsScreen .moreinfo {
  margin: 10px;
}
