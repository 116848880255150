
.profileGridItem {
  width: 90%;
  max-width: 140px;
  padding: 0;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0px 3px 0px #b77b6e;
}

.profileGridItem > div {
  width: 100%;
  padding-top: 120%;
  background-color: #fff;
  position: relative;
}

.profileGridItem > div > div {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}


.profileGridItem img {
  width: 58%;
  border-radius: 100%;
  flex-shrink: 0;
  margin-top: 10px;
}

.profileGridItem div.text {
  font-size: 10px;
  font-weight: bold;
  width: 100%;
  display: block;
  padding: 5px;
  box-sizing: border-box;
}

@media screen and (max-device-width: 320px) {
  .profileGridItem div.text {
    font-size: 8px;
  }
}

@media screen and (max-device-width: 360px) and (min-device-width: 321px) {
  .profileGridItem div.text {
    font-size: 9px;
  }
}


@media screen and (min-device-width: 400px) {
  .profileGridItem div.text {
    font-size: 11px;
  }
}

@media screen and (min-device-width: 500px) {
  .profileGridItem div.text {
    font-size: 12px;
  }
}


@media screen and (min-device-width: 640px) {
  .profileGridItem div.text {
    font-size: 14px;
  }
}

@media screen and (min-device-width: 800px) {
  #helpScreen .bottom {
    border-radius: 10px;
  }
}

.profileItem .itemLeft {
  flex-direction: row;
  display: flex;
}

.profileItem {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-left: 20px;
  margin-right: 20px;
}

.profileItem h3 {
  margin: 0px;
  margin-right: 10px;
}

.profileItem div.text {
  margin: 0px;
  text-align: left;
  margin-left: 15px;
  flex-shrink: 1;
}

.profileItem .text span {
  color: #566370 ;
}

.profileItem img {
  height: 54px;
  width: 54px;
  border-radius: 27px;
  flex-shrink: 0;
}

.profileItem button {
  margin: 0px;
  height: 40px;
  width: 80px;
  padding: 0px;
  text-align: center;
  flex-shrink: 0;
}

.profileItem .cta {
  font-size: 14px;
  margin-top: 8px;
}

.profileSection .header {
  text-align: left;
  margin-left: 20px;
  margin-right: 20px;
  padding-bottom: 3px;
  font-size: 18px;
}
.profileSection .profileItem {
  margin-top: 8px;
  margin-bottom: 8px;
}

.profileTweet {
  display: flex;  
  flex-direction: row;
  justify-content: center;
  width: calc(min(500px, 100%) - 20px);
  max-width: 500px;
  margin: 0 auto;
  text-align: left;
  cursor: pointer;
}

.profileTweet .whiteBackground {
  background-color: #FFFFFF;
}

.profileTweet.collapsed {

}

.profileTweetContainer.collapsed {
  position: relative;
  width: 100%;
  height: 56px;
}

.profileTweetContainer.expanded {
  position: relative;
  width: 100%;
}

.profileTweetContainer .expandableOverlay {
  width: 100%;
  z-index: 1000;
}

.profileTweetContainer .expandableOverlay.expanded {
  /*position: absolute;*/
  width: 100%;
}


.profileTweet img {
  height: 56px;
  width: 56px;
  border-radius: 28px;
}

.speechBubble {
  position: relative;
  padding: 4px;
  border: 2px solid #EDF1F2;
  border-radius: 10px;
  margin-left: 13px;
  height: 44px;
  flex-grow: 1;
  background-color: #FFFFFF;
}

.profileTweet.expanded .speechBubble {
  height: inherit;
}

/*
.speechBubble:before {
  top: 10px;
  bottom: auto;
  left: -30px;
  border-width: 15px 30px 15px 0;
  border-color: transparent #EDF1F2;
  content: "";
  position: absolute;
  border-style: solid;
  display: block;
  width: 0;
}
*/

.profileTweet .header {
  font-size: 1.1em;
}

.profileTweet .header .name {
  font-weight: bold;
}

.profileTweet .header .username {
  color: #566370;
  font-weight:normal ;
  margin-left: 5px;
}

.profileTweet .header .elapsedTime {
  color: #566370;
  font-weight:normal ;
}

.profileTweet .tweet {
  text-align: left;
  color: #111418;
  text-overflow: ellipsis;
  line-height: 18px;
}

.profileTweet .tweet.expanded {
  white-space: pre-wrap;
}

.profileTweet .tweet.collapsed {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.profileTweet .tweet.revealed {
  text-align: left;
  color: #566370;
  text-overflow: ellipsis;
}

.speechBubble:after {
  content: "";
  position: absolute;
  border-style: solid;
  display: block;
  width: 0;
  top: 16px;
  bottom: auto;
  left: -11px;
  border-width: 9px 11px 9px 0;
  border-color: transparent #EDF1F2;
}

.profileTweetContainer button {
  margin: 3px 0px;
  padding: 6px 18px;
  font-size: 10px;
}

.speechBubble .obfuscate {
  filter: blur(4px);
}

.speechBubble .link {
  color: rgb(29, 155, 240)
}

.speechBubble .highlight {
  font-weight: bold;
}

.imageAndButton {
  display: flex;
  flex-direction: column;
  min-height: 80px; /* needs height for calculation */
}

.profileTweet .imageAndButton button {
  vertical-align: middle;
  width: 56px;
  text-align: center;
  padding: 6px 0px;
  margin: 0 auto;
  margin-top: 2px;
}