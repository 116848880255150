
.announcementToast .profileItem {
  margin-top: 15px;
  margin-bottom: 15px;
}

.announcementToast .cta {
  font-size: 18px;
}

.announcementToast input {
  font-size: 18px;
  max-width: 140px;
}