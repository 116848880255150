.meView .exampleGrid .gridConstraint {
  margin: 0 auto;
}

.meView .profileTweetContainer  {
  margin-top: 10px;
}

.meView {
  font-size: 1.1em;
}

.meView a {
  font-size: 0.8em;
  text-decoration: underline;
  margin: 10px auto;
  display: block;
}

.meView p {
  margin: 10px;
}

.meView button.twitter {
  margin: 10px auto;
  font-size: 18px;
}

.meView .profileEntry {
  margin-top: 10px;
  margin-bottom: 10px;
}

.meView .profileEntry button {
  padding-left: 20px;
  padding-right: 20px;
  margin-left: 10px;
  margin-right: 10px;
}

.meView .profileEntry span {
  font-size: 20px;
}

.meView .profileEntry input {
  line-height: 30px;
  font-size: 20px;
  max-width: 200px;
}