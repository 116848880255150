@import url('./css/chillax.css');

.title {
  height: 36px;
  font-family: 'American Typewriter', serif;
  cursor: pointer;
}

body {
  background-size: cover;
  background-repeat: no-repeat;
  font-size: 0.8em;
}

body {
  color: var(--cell-color);
  font-family: var(--main-font);
}

:root {
  --cell-color: #111418;
  --cell-color-found: #FFFFFF;
  --cell-color-missed: #D2D3D8;
  --cell-color-font-size: 32px;
  --cell-color-font-size-small: 10px;
  --cell-color-font-size-tiny: 8px;

  --board-background-storke: #5E6066;

  --key-normal-color: #111418;
  --key-normal-color-pulse: #111418BB;
  --key-missed-color: #111418;
  --key-found-color: #FFFFFF;

  --button-corner-radius: 60px;

  --green-color: #89DB21;
  --yellow-color: #EECF2C;
  --missing-color: #F6F8F8;
  --empty-color: #D2D3D8;

  --tile-border-radius: 12px;
  --keyboard-border-radius: 10px;

  --panel-bg-color: #FFFFFF;
  --panel-bg-color-variant: #EDF1F2; 
  --panel-border-color: #EDF1F2;
  --panel-border-radius: 5px;

  --button-primary-bg-color: #79C816;
  --button-primary-color: #FFFFFF;
  --button-secondary-bg-color: #000000;
  --button-secondary-color: #FFFFFF;

  --main-font: "Helvetica Neue", 
  Helvetica, Arial, "Lucida Grande", sans-serif;

  --stat-bar-background: #D2D3D8;
  --stat-bar-fill: #89DB21;

  --banner-font-color: #111418;
  --banner-background-info: linear-gradient(180deg, rgba(2,0,36,0) 0%, rgba(210,211,216,1) 100%);
  --banner-background-reveal: linear-gradient(180deg, rgba(2,0,36,0) 0%, rgba(210,211,216,1) 100%);
  --banner-font-size-info: 16px;
  --banner-font-size-reveal: 24px;
  --banner-height: 34px;
}

#helpBtn {
  background: url(./assets/btnHelp.png);
  background-size: contain;
  background-size: 75%;
  background-position: center;
  background-repeat: no-repeat;
}

#helpBtn.active {
  background: url(./assets/btnHelp_active.png);
  background-size: 75%;
  background-position: center;
  background-repeat: no-repeat;
}

#settingsBtn {
  background: url(./assets/btnMenu.png);
  background-size: 75%;
  background-position: center;
  background-repeat: no-repeat;

}

#homeBtn {
  background: url(./assets/btnTwitter.png);
  background-size: 75%;
  background-position: center;
  background-repeat: no-repeat;
}

#homeBtn.active {
  background: url(./assets/btnTwitter_active.png);
  background-size: 75%;
  background-position: center;
  background-repeat: no-repeat;
}

#settingsBtn.active {
  background: url(./assets/btnMenu_active.png);
  background-size: 75%;
  background-position: center;
  background-repeat: no-repeat;
}

#walletBtn {
  background: url(./assets/btnWallet.png);
  background-size: 75%;
  background-position: center;
  background-repeat: no-repeat;
}

#walletBtn.active {
  background: url(./assets/btnWallet_active.png);
  background-size: 75%;
  background-position: center;
  background-repeat: no-repeat;
}

#statsBtn {
  background: url(./assets/btnStats.png);
  background-size: 75%;
  background-position: center;
  background-repeat: no-repeat;
}

#statsBtn.hidden {
  background: none;
}

#statsBtn.active {
  background: url(./assets/btnStats_active.png);
  background-size: 75%;
  background-position: center;
  background-repeat: no-repeat;
}

button, button.primary {
  background-color: var(--button-primary-bg-color);
  border: none;
  border-radius: var(--button-corner-radius);
  font-family: var(--main-font);
  color: var(--button-primary-color);
  cursor: pointer;
  font-size: 1.1em;
  font-weight: bold;
  padding: 10px 60px;
  outline: none;
}

button.twitter {
  background-color: #00ACEE;
  background-image: url(./assets/twitterIcon.png);
  background-size: 10%;
  background-repeat: no-repeat;
  background-position-y: center;
  background-position-x: 15px;
}

button.twitter.noimage {
  background-color: #00ACEE;
  background-image: none;
}

button.share {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 5px;
  box-shadow: 0px 3px 0px #417B2C;
}

button .shareicon {
  width: 20px;
}

button.primary.outline {
  background-color: #FFFFFF;
  border: solid 2px var(--button-primary-bg-color);
  color: var(--button-primary-bg-color);
}

button.secondary.outline {
  background-color: #FFFFFF00;
  border: solid 2px var(--button-secondary-bg-color);
  color: var(--button-secondary-bg-color);
}

button.secondary {
  background-color: var(--button-secondary-bg-color);
  border: none;
  color: var(--button-secondary-color);
}

.green {
  color: var(--green-color);
}

.gray {
  color: var(--cell-color-missed);
}

.yellow {
  color: var(--yellow-color);
}

.missed {
  color: var(--missing-color);
}

.grid > div > div.letterBull {
  background-size: cover;
  background-color: var(--green-color);
  border-radius: var(--tile-border-radius);
  color: var(--cell-color-found);
  font-size: var(--cell-color-font-size);
}

.grid > div > div.letterCow {
  background-size: cover;
  background-color: var(--yellow-color);
  border-radius: var(--tile-border-radius);
  color: var(--cell-color-found);
  font-size: var(--cell-color-font-size);
}

.grid > div > div.letterNone {
  background-size: cover;
  background-color: var(--missing-color);
  border-radius: var(--tile-border-radius);
  color: var(--cell-color-missed);
  font-size: var(--cell-color-font-size);
}

.grid > div > div.letterEmpty {
  background-size: cover;
  background-color: var(--empty-color);
  border-radius: var(--tile-border-radius);
  color: var(--cell-color);
  font-size: var(--cell-color-font-size);
}

.grid.small > div > div.letterCow,
.grid.small > div > div.letterBull,
.grid.small > div > div.letterEmpty,
.grid.small > div > div.letterNone {
  font-size: var(--cell-color-font-size-small)
}

.grid.tiny > div > div.letterCow,
.grid.tiny > div > div.letterBull,
.grid.tiny > div > div.letterEmpty,
.grid.tiny > div > div.letterNone {
  font-size: var(--cell-color-font-size-tiny)
}


#playingScreen .grid {
  background-color: #FFFFFF;
  border-radius: 5px;
  /*border: #5E6066 solid 1px;*/
}

.keyboard > div.row {
  margin-top: 5px;
}

.keyboard > div > div.letterBull {
  background-size: cover;
  background-color: var(--green-color);
  color: var(--key-found-color);
  border-radius: var(--keyboard-border-radius);
}

.keyboard > div > div.letterCow {
  background-size: cover;
  background-color: var(--yellow-color);
  color: var(--key-found-color);
  border-radius: var(--keyboard-border-radius);
}

.keyboard > div > div.letterNone {
  background-size: cover;
  background-color: #B4B5BA;
  color: var(--key-normal-color);
  border-radius: var(--keyboard-border-radius);
}

.keyboard > div > div.letterEmpty {
  background-size: cover;
  background-color: #FFFFFF;
  border-radius: var(--keyboard-border-radius);
  box-shadow: 0px 1px #868B8C;
  color: var(--key-normal-color)
}

.keyboard > div > div.hintKey {
  animation: pulse 2s infinite;
  color: var(--key-normal-color)
}

@keyframes pulse {
  0% {
    transform: scale(0.90);
    box-shadow: 0 0 0 0 rgba(52, 53, 70, 0.8);
  }
  
  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(52, 53, 70, 0);
  }
  
  100% {
    transform: scale(0.90);
    box-shadow: 0 0 0 0 rgba(52, 53, 70, 0.0)
  }
}

.App .gameScreen .bottom {
  background-color: #D2D3D8;
}

.keyboard > div > div.enter {
  background-color: #FFFFFF;
  border-radius: var(--keyboard-border-radius);
  box-shadow: 0px 1px #868B8C;
  padding: 0 6px;
}

.keyboard > div > div.delete {
  background-color: #FFFFFF;
  border-radius: var(--keyboard-border-radius);
  box-shadow: 0px 1px #868B8C;
  padding: 0 6px;
}

.keyboard > div > div.highlighted {
  background-color: #FF9A24;
  color: #FFFFFF;
} 

.App .primary div.gridConstraint {
}

#helpScreen {
  max-width: 800px;
  margin: 0 auto;
  font-size: 1em;
  background-color: #EDF1F2;
}

#helpScreen .examples {
  margin-top: 10px;
  text-align: left;
  background-color: #FFFFFF;
}

#helpScreen .examplesVariation {
  margin-top: 10px;
  text-align: left;
}

#helpScreen .examples .content {
  padding: 0;
}

#helpScreen button {
  font-size: 18px;
  margin: 0 5px;
}

#helpScreen .screen {
  background-color: #EDF1F2;
}

#helpScreen .bottom {
  background-color: #FF9B25;
}

#helpScreen .helpVariation {
  background-color: #EDF1F2;
}

.helpViewContainer {
  background-color: #EDF1F2;
  height: 100%;
}

.profileSelectionGrid {
  padding-bottom: 10px;
}

.profileSelectionGrid div.titleText {
  color: #FFFFFF;
  font-weight: bold;
  font-size: 1.2em;
  padding-top: 11px;
  padding-bottom: 11px;
}

.profileSelectionGrid > div.profileSquare {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 10px;
  margin: 0 0 10px 0;
  padding: 0 15px;
}

.profileSelectionGrid > div.profileSquare:last-child {
  margin-bottom: 0;
}

.profileSquare > div.profileGridItem:hover {
  background-color: rgba(255, 255, 255, 0.3);
}