* {
  user-select: none;
}
input {
  user-select: auto !important;
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-float infinite 3s ease-in-out;
  }
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.App-link {
  color: rgb(112, 76, 182);
}

@keyframes App-logo-float {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(0px);
  }
}

.clickCatcher {
  height: 0;
  width: 100%;
}

.App {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden;
}

.App header {
}

.App header nav {
  width: calc(100% - 20px);
  display: flex;
  flex-direction: row;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 10px;
  padding-right: 10px;
  border-bottom: solid 1px #EDF1F2;
}

.App header nav h1 {
  flex-grow: 1;
  margin: 0;
  padding: 0;
}

.App header nav .button {
  width: 34px;
  height: 34px;
  margin-left: 3px;
  margin-right: 3px;
  cursor: pointer;
}

.App header nav .button.hidden {
  background: none !important;
}

.App .mainArea {
  flex-grow: 1;
  display: contents;
}

.App .gameScreen {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  position: relative;
  overflow: hidden;
}

.App .gameScreen .resultContent {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 175px;
}

.keyboard {
  position: relative;
  top: 5px;
}

.App .gameScreen .resultContent .shareButtonsVert button,
.App .gameScreen .resultContent .shareButtons button {
  font-size: 19px;
  margin-left: 5px;
  margin-right: 5px;
  min-width: 270px;
}

.App .gameScreen .resultContent .shareButtonsVert button.primary.share,
.App .gameScreen .resultContent .shareButtons button.primary.share {
  min-width: 140px !important;
  background-size: 20px;
  font-size: 14px;
  padding-top: 7px;
  padding-bottom: 7px;
}

.resultContent .triggerText {
  font-family: 'American Typewriter', serif;
  font-size: 1.25em;
  margin-left: 10px;
  margin-right: 10px;
}

.App .gameScreen .resultContent .shareButtons {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.App .gameScreen .resultContent .shareButtonsVert {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.App .gameScreen .resultContent .shareButtonsVert button.primary.share {
  margin-top: 10px;
  width: 130px;
}

.App .gameScreen div.primary {
  flex-grow: 1;
  min-height: 0;
  overflow: auto;
}

.App .gameScreen .gridConstraint {
  margin: 0 auto;
}

#settingsScreen button {
  margin: 10px 0;
}

.bannerContainer {
  position: relative;
  height: 24px;
}

.banner {
  display: flex;
  flex-direction: column;;
  align-items: center;
  justify-content: center;
}

.banner {
  height: 0px;
  text-align: center;
  overflow: hidden;
}

.banner.info {
  background: rgb(2,0,36);
  background: var(--banner-background-info);
  font-size: var(--banner-font-size-info);
  color: var(--banner-font-color);
}

.banner.reveal {
  background: rgb(2,0,36);
  background: var(--banner-background-reveal);
  font-size: var(--banner-font-size-reveal);
  color: var(--banner-font-color);
  text-transform: uppercase;
  font-weight: bold;
}

#helpScreen {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

#helpScreen .gridConstraint  {
  margin: 0 auto;
}

#helpScreen button {
  margin-top: 10px;
}

#helpScreen p {
  margin: 0px;
  padding-left: 50px;
  padding-right: 35px;
}

#helpScreen h3 {
  margin: 0;
  padding: 8px;
}

#helpScreen .bottom {
  margin-top: auto;
  width: 100%;
}

@media screen and (min-device-width: 640px) {
  #helpScreen .bottom {
    margin-top: 0;
  }
}

.App .statsView h3, .App .statsView h2 {
  text-transform: uppercase;
}

.App .statsView .metrics {
  display: flex;
  align-items: center;
  justify-content: center;
}

.App .statsView .metrics > div {
  margin: 0 10px;
  width: 80px;
}

.App .statsView .metrics > div > div:first-child {
  font-size: 0.8em
}

.App .statsView .metrics > div > div:last-child {
  font-size: 1.4em
}

.statsView div.wins {
  background-size: contain;
  background-repeat: no-repeat;
  background-position-x: center;
  background-size: 80%;
}

.statsView div.streak {
  background-size: contain;
  background-repeat: no-repeat;
  background-position-x: center;
}

.statsView div.winPerc {
  background-size: contain;
  background-repeat: no-repeat;
  background-position-x: center;
  background-size: 70%;
}

.App .panel {
  max-width: min(600px, 100% - 20px);
  padding: 10px;
  background-color: var(--panel-bg-color-variant);
  border: var(--panel-border-color) solid 1px;
  border-radius: var(--panel-border-radius);
}

.panel ::-webkit-scrollbar {
  width: 4px;
}

.App .panel .content.scrollable {
  overflow-y: scroll;
  clear: both;
  padding-top: 0;
  padding-bottom: 10px;
}

.panel ::-webkit-scrollbar-track {
  display: none;
}

.panel .content.scrollable h3,
.panel .content.scrollable h2,
.panel .content.scrollable h1 {
  margin: 0;
}

.panel ::-webkit-scrollbar-thumb {
  background: white;
  width: 8px;
}

@media only screen and (max-width: 600px) {
  .App .panel {
    margin: 0 10px;
  }
}

@media only screen and (min-width: 600px) {
  .App .panel {
    margin: 0 auto;
  }
}

.distributions {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 80%;
  margin: 0 auto;
}

.distributions .bars, .distribution .text {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin: 2px 0;
  font-size: .8em;
}

.distributions .bars .barBackground {
  max-width: 80px;
  height: 15px;
  background-color: var(--stat-bar-background);
  display: flex;
  margin-right: 10px;
  margin-top: 2px;
  margin-bottom: 2px;
  flex-grow: 1;
  border-radius: 2px;
}

.distributions .bars .bar {
  height: 15px;
  background-color: var(--stat-bar-fill);
  border-radius: 2px;;
}

.distributions .text .stat {
  text-align: left;
  line-height: 19px;
  display: block;
}

.App .panel .content {
  padding: 10px 0px;
}

.App .panel .x {
  width: 44px;
  height: 44px;
  margin-right: -10px;
  margin-top: -10px;
  float: right;
  background-image: url(./skin/assets/btnX.svg);
  background-size: 20px;
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
}

.App .gameScreen .bottom {
  display: grid;
  height: 205px;
  flex-grow: 0;
}

.keyboardContainer {
  grid-column: 1;
  grid-row: 1;
}

.resultsContainer {
  grid-column: 1;
  grid-row: 1;
}

.homeView {
  max-width: 800px;
  width: 100%;
  margin: 0 auto;
  height: 100%;
  overflow-y: hidden;
}

.homeViewScroll {
  overflow-y: scroll;
}

.App .statsView button {
    margin-top: 10px;
    font-size: 18px;
  }

  .profileItem {
    cursor: pointer;
  }

  .bannerContainer img {
    width: 30px;
    height: 30px;
    border-radius: 15px;
    margin-right: 10px;
  }

  .banner {
    display: flex;
    flex-direction: row;
  }

  .loadingScreen {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .gameScreen .profileTweetContainer {
    margin-top: 5px;
    flex-grow: 0;
  }

  .keyboard .message {
    font-family: 'American Typewriter', serif;
    font-size: 1.3em;
    margin: 10px 3px;
  }

  #helpScreen p.legal {
    font-size: 0.8em;
    margin: 3px auto;
    padding-top: 10px;
    padding-bottom: 10px;
  }

  #helpScreen p.legalVariation {
    font-size: 0.8em;
    margin: 3px auto;
    color: #FFFFFF;
    padding-bottom: 10px;
  }

  .toggle-switch {
    position: relative;
    width: 50px;
    display: inline-block;
    vertical-align: middle;
    text-align: left;
    margin-top: 5px;
    margin-left: 3px;
  }
  .toggle-switch-checkbox {
    display: none;
  }
  .toggle-switch-label {
    display: block;
    overflow: hidden;
    cursor: pointer;
    border-radius: 20px;
    margin: 0;
  }
  .toggle-switch-inner {
    display: block;
    width: 200%;
    margin-left: -100%;
    transition: margin 0.3s ease-in 0s;
  }
  .toggle-switch-inner:before,
  .toggle-switch-inner:after {
    display: block;
    float: left;
    width: 50%;
    height: 20px;
    padding: 0;
    line-height: 21px;
    font-size: 11px;
    color:white;
    font-weight: bold;
    box-sizing: border-box;
    font-family: 'Headland One';
  }
  .toggle-switch-inner:before {
    content: attr(data-yes);
    padding-left: 5px;
    background-color: #FF9A24;
    color: #fff;
  }
  
  .toggle-switch-inner:after {
    content: attr(data-no);
    padding-right: 5px;
    background-color: #89DB21;
    color: #fff;
    text-align: right;
  }
  .toggle-switch-switch {
    display: block;
    width: 15px;
    height: 15px;
    margin: 2px;
    background: #fff;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 31px;
    border: 0 solid #bbb;
    border-radius: 100%;
    transition: all 0.3s ease-in 0s;
    box-shadow: 0px 2px 0px #868B8C;
  }
  .toggle-switch-checkbox:checked + .toggle-switch-label .toggle-switch-inner {
    margin-left: 0;
  }
  .toggle-switch-checkbox:checked + .toggle-switch-label .toggle-switch-switch {
    right: 0px;
  }
