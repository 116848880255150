html, body, header {
  margin: 0px;
  padding: 0px;
}

body {
  margin: auto;
}

#helpBtn.selected,
#settingsBtn.selected,
#statsBtn.selected {
  border-radius: 2px;
  background-color: var(--other-color);
}

.screen {
  text-align: center;
}

.popup > h2 {
  margin: 0;
  margin-top: -10px;
  margin-left: -10px;
  margin-right: -10px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  border-bottom: 2px solid #a3a8a4;
  padding: 8px;
  background-color: var(--header-bg-color);
}

.popup {
  position: fixed;
  top: 100px;
  left: 50%;
  padding: 10px;
  transform: translate(-50%, 0);
  margin: auto;
  background-color: #f0f0f0;
  border-radius: 8px;
  max-width: 500px;
  min-width: 300px;
  min-height: 100px;
  z-index: 100;
  text-align: center;
}

#underlay {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: black;
  z-index: 99;
  display: none;
  opacity: 50%;
}

/* Grid */
.grid {
  height: calc(100% - 20px);
  width: calc(100% - 20px);
  padding: 10px;
}

.grid > div > div {
  flex-grow: 1;
  cursor: pointer;
  font-weight: bold;
  font-size: 28px;
  display: flex;
  justify-content: center;
  align-items: center; 
  user-select: none;
}

/* Keyboard row */
.keyboard > div {
  display: flex;
  justify-content: center;
  gap: 3px;
  margin-bottom: 2px;
}

/* Keyboard key */
.keyboard > div > div {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 52px;
  min-width: 35px;
  max-height: 64px;
  min-height: 52px;
  border-radius: 4px;
  background: var(--keyboard-color);
  cursor: pointer;
  font-weight: bold;
  user-select: none;
}

@media only screen and (max-width: 324px) {
  .keyboard > div > div {
    min-width: 27px !important;
  }
}

@media only screen and (min-width: 400px) {
  .keyboard > div > div {
    min-width: 37px !important;
  }
}

@media only screen and (max-width: 380px) and (min-width: 371px) {
  .keyboard > div > div {
    min-width: 34px !important;
  }
}


@media only screen and (max-width: 370px) and (min-width: 351px) {
  .keyboard > div > div {
    min-width: 32px !important;
  }
}

@media only screen and (max-width: 350px) and (min-width: 325px) {
  .keyboard > div > div {
    min-width: 29px !important;
  }
}

:root {
  --header-bg-color: #e1e8e2;
  --font-color: #2c3e50;
  --icon-selected-bg-color: #c8cfc9;
  --popup-bg-color: #edf5ee;
  --other-color: #a3a8a4;

  --keyboard-color: #e1e8e2;

  /* Tile colors */
  --letter-none: #656966;
  --letter-empty: white;
  --letter-empty-outline: #656966;
  --tile-color: #e1e8e2;
  --title-outline: #e1e8e2;
}
